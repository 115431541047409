<!-- 集团管理 首页 -->
<template>
  <section class="cont group_index">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="search-box">
        <el-row class="search-row" style="margin-bottom:0">
          <el-row class="search-item">
            <el-radio-group v-model="checkboxGroup" @change="radioChange">
              <el-radio-button
                v-for="(state, idx) in statisticsStateList"
                :key="idx"
                :label="state.value"
                :value="state.value"
              > {{state.label}}</el-radio-button>
            </el-radio-group>
          </el-row>
          <el-row class="search-item">
            <el-date-picker
              v-if="checkboxGroup === 'customDate'"
              ref="datePicker"
              value-format="yyyy-MM-dd"
              class="date-ipt"
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-row>
          <el-button v-if="checkboxGroup === 'customDate'" type="primary" class="m-left-auto" @click="getData()">搜索</el-button>
        </el-row>
      </el-row>

      <el-row class="card-box m-top-50" type="flex">
        <div class="card-box-item">
          <p class="item-tit">小程序分享次数</p>
          <p class="item-date">{{dateSplice}}</p>
          <p class="item-text">{{ basicData.count || 0 }}次</p>
        </div>
        <div class="card-box-item" style="margin: 0 100px">
          <p class="item-tit">小程序受邀注册用户数</p>
          <p class="item-date">{{dateSplice}}</p>
          <p class="item-text">{{ basicData.inviteeCount || 0 }}次</p>
        </div>
        <div class="card-box-item">
          <div>
            <p class="item-tit">小程序受邀用户占比</p>
            <p class="item-date">{{dateSplice}}</p>
            <p class="item-text">{{ basicData.inviteeRate || 0 }}%</p>
          </div>
        </div>
        <div class="card-box-item" style="margin-left:100px">
          <div>
            <p class="item-tit">小程序分享成功率</p>
            <p class="item-date">{{dateSplice}}</p>
            <p class="item-text">{{ basicData.successRate || 0 }}%</p>
          </div>
        </div>
      </el-row>
    </el-row>
    
     <div class="m-top-20 chart-box">
      <el-col class="content-box chart-item-bg">
        <p class="m-bottom-10">小程序30天分享次数</p>
        <span>{{shareDate[0] + '~' + shareDate[1]}}</span>
        <CurveChart class="myChart" :chartId="'share'" :xAxis="dateList" :name="'分享次数'" :series="countList" ref="share"/>
      </el-col>
    </div>
  </section>
</template>

<script>
import { dateFactory } from "@/common/js/common";
import { getShareCount, getStatisticsCount } from "@/api/business/channelKanban/trafficTrack.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      crumbs: new Map([['业务中台'], ['渠道看板'], ['小程序流量追踪']]),
      checkboxGroup: "yesterday",
      statisticsStateList: [
        { label: "昨日", value:'yesterday' },
        { label: "7日", value: 'week' },
        { label: "30日", value: 'month' },
        { label: "选择日期", value: 'customDate' },
      ],
      dateRange: [], // 请求小程序流量追踪数据日期
      StatisticsDate: [], // 展示日期
      basicData: {}, // 小程序流量追踪数据
      shareDate: [], // 近30天日期
      dateList: [], // 小程序近30天分享日期
      countList: [] // 小程序近30天分享次数
    };
  },
  mounted() {
    this.getWeekList = dateFactory.getWeekList()
    this.shareDate = this.getMonth()
    this.radioChange()
    this.getShareNum()
  },
  methods: {
    // 获取小程序流量追踪数据
    getData() {
      let params = {
        hotelId: this.hotelInfo.id
      }
      if (!this.dateRange[1]) {
        params.date	 = this.dateRange[0]
      } else {
        params.startDate = this.dateRange[0]
        params.endDate = this.dateRange[1]
      }
      getStatisticsCount(params).then(res => {
        this.basicData = res.records
        this.StatisticsDate = this.dateRange
      })
    },

    // 小程序近30天分享次数
    getShareNum() {
      let params = {
        hotelId: this.hotelInfo.id,
        startDate:  this.shareDate[0],
        endDate: this.shareDate[1]
      }
      getShareCount(params).then(res => {
        if (res.success) {
          for (let i in res.records) {
            this.dateList[i] = res.records[i].date.slice(5)
            this.countList[i] = res.records[i].count
          }
        }
         this.$refs.share.drawChart()
      })
    },

    // 单选框事件
    radioChange() {
      switch (this.checkboxGroup) {
        case "yesterday":
          this.dateRange = [dateFactory.getDistanceToday(-1,false)];
          break;
        case "week":
          this.dateRange = [dateFactory.getDistanceToday(-7, false), dateFactory.getDistanceToday(0, false)];
          break;
        case "month":
          this.dateRange = this.getMonth();
          break;
        default: this.dateRange = []; return
      }
      this.getData()
    },
    //获取本月开始日期+结束日期
    getMonth(){
      let arr = [dateFactory.getDistanceToday(-30, false), dateFactory.getDistanceToday(0, false)]
      return arr;
    }
  },
  computed: {
    ...mapState(["hotelInfo"]),
    dateSplice() {
      return  this.StatisticsDate[1] ? this.StatisticsDate[0] + '~' + this.StatisticsDate[1] : this.StatisticsDate[0]
    }
  },
}
</script>

<style lang="scss" scoped>
.card-box {
  $gradient-blue: linear-gradient(90deg, #006fff, #189aff);
  $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
  $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
  $gradient-yellow: linear-gradient(
    90deg,
    rgb(255, 161, 50),
    rgb(255, 209, 50)
  );
  $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
  $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow,
    $gradient-red;
  .card-box-item {
    width: 20%;
    padding: 10px 20px;
    background-repeat: no-repeat;
    text-align: center;
    background-position: 0 0;
    background-size: 100% 100%;
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 6px;
    @each $c in $list-bg {
      $i: index($list-bg, $c);
      &:nth-child(#{$i}) {
        background: nth($list-bg, $i);
      }
    }
    .item-tit {
      text-align: left;
      font-size: 18px;
      font-weight: 400;
    }
    .item-date {
      text-align: left;
    }
    .item-text {
      margin:30px 0;
      font-size: 30px;
      font-weight: 700;
    }
    .item-rect {
      background: #f1f1f1;
      width: 10px;
      height: 18px;
      margin-right: 3px;
    }
    .item-rect-ac {
      background: #ff2332;
    }
  }
}

 .chart-box {
      font-size: 18px;
      .chart-item-bg {
        .myChart {
          width: 100%;
          height: 300px;
        }
      }
    }
</style>