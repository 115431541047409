import request from "@/api/service";

const api = {
   getShareCount: '/member/memberShare/getShareCount', // 小程序30天分享次数
   getStatisticsCount: '/member/memberShare/getStatisticsCount', // 小程序流量跟踪数量统计
};

export function getShareCount(data) {
   return request({
      url: api.getShareCount,
      method: 'POST',
      data
   })
}

export function getStatisticsCount(data) {
   return request({
      url: api.getStatisticsCount,
      method: 'POST',
      data
   })
}

